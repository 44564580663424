import { Injectable } from '@angular/core';
import { MetaData } from '../models/seoMetaData';
import { Services } from '../models/services';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor() {
    
   }



  getSEOMetaData(page:string){
    let meta = MetaData.filter((res:any) => res.page == page);
    return [
      { name: 'keywords', content: meta[0].metaKeywords },
      { name: 'description', content: meta[0].metaDescription }
    ];
  }

  getTitle(page:string){
    let meta = MetaData.filter((res:any) => res.page == page);
    return meta[0].metaTitle;
  }

  getServicesSEOMetaData(page:string){
    let meta = Services.filter((res:any) => res.id == page);
    return [
      { name: 'keywords', content: meta[0].metaKeywords },
      { name: 'description', content: meta[0].metaDescription }
    ];
  }

  getServicesTitle(page:string){
    let meta = Services.filter((res:any) => res.id == page);
    return meta[0].metaTitle;
  }
  
}
