const MetaData = [
    {
        "page": "home",
        "metaDescription": "Get Local professionals for Building Plans, Land clearance, building approvals &amp; other construction activities. Search for FSI in Pune, Cost calculator, Online 7/12 etc. . .",
        "metaKeywords": "Foot2Feet, Local professionals, pcmc, pmc",
        "metaTitle": "One Place Platform in Construction - Foot2Feet"
    },
    {
        "page": "questions",
        "metaDescription": "Get Local professionals for Building Plans, Land clearance, building approvals &amp; other construction activities. Search for FSI in Pune, Cost calculator, Online 7/12 etc. . .",
        "metaKeywords": "Foot2Feet, Local professionals, pcmc, pmc",
        "metaTitle": "Questions "
    },
    {
        "page": "services",
        "metaDescription": "Get Local professionals for Building Plans, Land clearance, building approvals &amp; other construction activities. Search for FSI in Pune, Cost calculator, Online 7/12 etc. . .",
        "metaKeywords": "Foot2Feet, Local professionals, pcmc, pmc",
        "metaTitle": "Services - Foot2Feet"
    },
    {
        "page": "all-services",
        "metaDescription": "Get Local professionals for Building Plans, Land clearance, building approvals &amp; other construction activities. Search for FSI in Pune, Cost calculator, Online 7/12 etc. . .",
        "metaKeywords": "Foot2Feet, Local professionals, pcmc, pmc",
        "metaTitle": "All Services - Foot2Feet"
    },
    {
        "page": "all-calculators",
        "metaDescription": "Get Local professionals for Building Plans, Land clearance, building approvals &amp; other construction activities. Search for FSI in Pune, Cost calculator, Online 7/12 etc. . .",
        "metaKeywords": "Foot2Feet, Local professionals, pcmc, pmc",
        "metaTitle": "All calculators - Foot2Feet"
    },
    {
        "page": "fsi",
        "metaDescription": "Get Local professionals for Building Plans, Land clearance, building approvals &amp; other construction activities. Search for FSI in Pune, Cost calculator, Online 7/12 etc. . .",
        "metaKeywords": "Foot2Feet, Local professionals, pcmc, pmc",
        "metaTitle": "FSI Calculator - Foot2Feet"
    },
    {
        "page": "side-margin",
        "metaDescription": "Get Local professionals for Building Plans, Land clearance, building approvals &amp; other construction activities. Search for FSI in Pune, Cost calculator, Online 7/12 etc. . .",
        "metaKeywords": "Foot2Feet, Local professionals, pcmc, pmc",
        "metaTitle": "Side Margin Calculator - Foot2Feet"
    },
    {
        "page": "sign-in",
        "metaDescription": "Get Local professionals for Building Plans, Land clearance, building approvals &amp; other construction activities. Search for FSI in Pune, Cost calculator, Online 7/12 etc. . .",
        "metaKeywords": "Foot2Feet, Local professionals, pcmc, pmc",
        "metaTitle": "SignIn - Foot2Feet"
    },
    {
        "page": "blogs",
        "metaDescription": "Get Local professionals for Building Plans, Land clearance, building approvals &amp; other construction activities. Search for FSI in Pune, Cost calculator, Online 7/12 etc. . .",
        "metaKeywords": "Foot2Feet, Local professionals, pcmc, pmc",
        "metaTitle": "Blogs - Foot2Feet"
    },
]

export { MetaData };